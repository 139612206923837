<template>
  <el-breadcrumb :separator="separator">
    <el-breadcrumb-item
      v-for="item in list"
      :key="item.id"
      :to="item.path && { path: item.path }"
    >
      {{ item.label }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  props: {
    separator: {
      type: String,
      default: "/",
    },
    list: {
      type: Array,
      require: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.el-breadcrumb {
  font-size: 16px;
  /deep/ .el-breadcrumb__inner a,
  /deep/ .el-breadcrumb__inner.is-link {
    color: @themeBlue;
  }
}
</style>